import React from "react";

export const Features = (props) => {
  return (
    <header id="header">
      <div className="intro2">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-md-offset-2 col-xs-12 intro2-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p></p>
                {" "}
              </div>
              <div className="col-md-6 col-xs-12 col-md-offset-2">
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
