import ReactDOM from 'react-dom';
import React from "react";

import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';
import 'react-slideshow-image/dist/styles.css'

ReactDOM.render(
  <React.StrictMode>
    {/* <Navigation /> */}
    <App/>
    {/* <Contact data={JsonData.Contact}/> */}
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
