import React, { useState, useEffect } from "react";
import { PageTitle } from "./pageTitle";
import JsonData from "../data/data.json";
import "../App.css";
import { BodyContrato } from "./bodycontrato";


const ContratoPF = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    return (
        <>
        <PageTitle title="Contrato pessoa Física" />
        <BodyContrato />
        </>
    )
};

export default ContratoPF